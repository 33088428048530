<template>
  <b-form-input v-if="items.length===0" :disabled="true" :class="placeHolder===null?`font-italic`:``" :value="getName" />
  <b-form-select v-else :id="id" :disabled="disabled"
      :value="value" @change="onSelect" :options="items"
      value-field="uuid4" text-field="tableName" :form="form">
    <template #first>
      <b-form-select-option :value="-1" :disabled="true">
        {{ $t('dial.selopt') }}
      </b-form-select-option>
      <b-form-select-option :value="null">
        {{ $t('res.empty') }}
      </b-form-select-option>
    </template>
  </b-form-select>
</template>

<script>
import { REST } from '@/components/RestCall';
import Query from '@/components/RestQuery';

export default {
  data() {
    return {
      query: {},
    };
  },
  created() {
    Query.setSort(this.query, false, 'tableName', 'uuid4');
    if (this.autoload) {
      this.loadItems();
    }
  },
  computed: {
    items() {
      return this.$store.state.oTables.items;
    },
    isLoading() {
      return !this.$store.state.oTables.isFinished;
    },
    getName() {
      if (this.placeHolder == null) return this.$t('res.empty');
      return this.placeHolder;
    },
  },
  methods: {
    onSelect(uuid) {
      this.$emit('input', { tableUuid4: uuid, tableName: (uuid === null) ? null : this.items.find(e => e.uuid4 === uuid).tableName });
    },
    loadItems() {
      // the following "this.items.length" works as long as it is single-user: others may have added new values already
      if (this.$store.state.oTables.isFinished === true && !this.items.length) {
        this.$store.commit('clearTables');
        this.$store.commit('finishTables', false);
        this._load();
      }
    },
    async _load(next = null, skipReset = false) {
      try {
        Query.setPagi(this.query, next, 25);
        const resp = await REST.get('/customers/' + this.$store.getters.uuid + '/tables', { params: this.query });
        this.$store.dispatch('addTables', resp.d.data);

        if (resp.d.links.next !== '') {
          await this._load(resp.d.links.next, true);
        }
      } catch (e) {
        // catch 404 warning: cust may not have added tables yet
        if (e.code != 404) {
          this.$store.dispatch('showWarn',e.message);
        }
        return;
      } finally {
        if (skipReset === false) {
          this.$store.dispatch('finishTables', true);
        }
      }
    },
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    form: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeHolder: {
      type: String,
      default: null,
    },
    autoload: {
      type: Boolean,
      default: false,
    }
  },
}
</script>
